//
// This is the client side entry point for the React app.
//

import React from 'react';
import { routes } from './routes';
import { getInitialState} from "./utils/initialStore";
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import rootReducer from './root-reducer';
import thunkMiddleware from 'redux-thunk';
import { browserHistory } from 'react-router';
import { ClientAuthMiddleware } from 'doctify-utils';
import { routerMiddleware } from 'react-router-redux';
import { createStore, applyMiddleware, compose } from 'redux';

import 'bootstrap-sass/assets/stylesheets/_bootstrap.scss';
import 'ionicons/scss/ionicons.scss';
import 'assets';

getInitialState().then(initialStore => {
    const initialState = initialStore.getState();
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        rootReducer, initialState,
        composeEnhancers(
            applyMiddleware(
                thunkMiddleware, ClientAuthMiddleware, routerMiddleware(browserHistory)
            )
        )
    );

    render(
        <Provider store={store}>
            { routes({store}) }
        </Provider>,
        document.querySelector('.js-content')
    );
});

