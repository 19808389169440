import React from 'react';
import { Route, IndexRoute, Router, browserHistory } from 'react-router';

import { WidgetHome } from 'widgets-home';
import { LoginWidget } from 'login-widget';
import { BookingWidget } from 'booking-widget';
import { WidgetExamples } from 'widget-examples';
import { BookButtonWidget } from 'book-button-widget';
import { ReviewBadgeWidget } from 'review-badge-widget';
import { ProfileReviewsWidget } from 'profile-reviews-widget';

import { syncHistoryWithStore } from 'react-router-redux';

import { WfGridWidget } from 'wf-grid-widget'
import { WfMicroStarWidget } from 'wf-micro-star-widget'
import { WfHorizontalWidget } from 'wf-horizontal-widget'
import { WfCarouselWidget } from 'wf-carousel-widget'
import { WfListWidget } from 'wf-list-widget'
import { WfPeerRecommendationWidget } from 'wf-peer-recommendation-widget';
import { WfGroupReviewsWidget } from 'wf-group-reviews-widget';

import { addLocaleData, IntlProvider } from 'react-intl';

// const [locale,] = (typeof navigator !== 'undefined' && navigator.languages) || ['en'];
// const [localeBase,] = locale.split('-');
const localeBase = 'en';
const lang = require(`react-intl/locale-data/${localeBase}`);
let messages;
try {
    messages = require(`../../src/lang/${localeBase}.json`);
} catch (error) {
    messages = require('../../src/lang/en.json');
}
addLocaleData(lang);

export const routes = ({store}) => (
    <IntlProvider locale={localeBase} messages={messages}>
        <Router history={store ? syncHistoryWithStore(browserHistory, store) : browserHistory}>
            <Route path="/" component={WidgetHome}/>
            <Route path="/reviews-badge" component={ReviewBadgeWidget}/>
            <Route path="/reviews-badge-widget" component={ReviewBadgeWidget}/>
            <Route path="/profile-reviews" component={ProfileReviewsWidget}/>
            <Route path="/widgets-examples" component={WidgetExamples}/>
            <Route path="/booking-calendar" component={BookingWidget}/>
            <Route path="/book-button" component={BookButtonWidget}/>
            <Route path="/oauth" component={LoginWidget}/>
            <Route path="/wf-grid-widget" component={WfGridWidget}/>
            <Route path="/wf-micro-star-widget" component={WfMicroStarWidget}/>
            <Route path="/wf-horizontal-widget" component={WfHorizontalWidget}/>
            <Route path="/wf-carousel-widget" component={WfCarouselWidget}/>
            <Route path="/wf-list-widget" component={WfListWidget}/>
            <Route path="/wf-peer-recommendation-widget" component={WfPeerRecommendationWidget}/>
            <Route path="/wf-group-reviews-widget" component={WfGroupReviewsWidget}/>
        </Router>
    </IntlProvider>
);
