// @flow
import { loginWidget } from 'login-widget';
import { bookingWidget } from 'booking-widget';
import { widgetExamples } from 'widget-examples';
import { reviewBadge } from 'review-badge-widget';
import { profileReviewsWidget } from 'profile-reviews-widget';

import { combineReducers } from 'redux';
import globalRedux from 'doctify-global-redux';
import { reducer as session } from 'redux-simple-auth';
import { routerReducer as routing } from 'react-router-redux';
import {wfGrid} from 'wf-grid-widget'
import {wfMicroStar} from 'wf-micro-star-widget'
import {wfHorizontal} from 'wf-horizontal-widget'
import {wfCarousel} from 'wf-carousel-widget'
import {wfList} from 'wf-list-widget'
import {wfPeerRecommendation} from 'wf-peer-recommendation-widget';
import {wfGroupReviews} from 'wf-group-reviews-widget';

const global = globalRedux.global;

export default combineReducers({
	global,
	routing,
	session,
	loginWidget,
	reviewBadge,
	bookingWidget,
	widgetExamples,
	profileReviewsWidget,
    wfGrid,
    wfMicroStar,
    wfHorizontal,
    wfCarousel,
    wfList,
    wfPeerRecommendation,
    wfGroupReviews
});
