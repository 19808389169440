import { createStore, applyMiddleware } from 'redux';
import rootReducer from "../root-reducer";
import thunkMiddleware from "redux-thunk";
import axios from "axios";
import { PageLoader } from "doctify-utils";
import _ from "lodash";

export const getInitialState = () => {
    const store = createStore(rootReducer, {}, applyMiddleware(thunkMiddleware));

    if (window && document && location) {

        const baseURL = location.hostname.indexOf('localhost') > -1 ? 'https://development.doctify.co.uk' : '';

        const xhrConfig = {
            xhrAdapter: axios.request,
            baseUrl: baseURL
        };

        return utilsLoader(location.pathname).then(utils => {
            const loaders = utils.loaders;
            const dynamicArgumentsMap = utils.dynamicArgumentsMap;

            let request = {};

            const search = location.search.substring(1);
            if (search.length > 0) {
                const query = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                    return key === "" ? value : decodeURIComponent(value);
                });

                let parsedQuery = {};
                Object.keys(query).forEach((key) => {
                    let value = query[key];
                    switch(value) {
                        case "true":
                            value = true;
                            break;
                        case "false":
                            value = false;
                            break;
                    }
                    parsedQuery[key] = value;
                });

                request = {
                    query: parsedQuery
                };
            }

            return new PageLoader(loaders)
                .runOnce(store.dispatch, {}, xhrConfig, mapDynamicArguments(request, dynamicArgumentsMap))
                .then(() => {
                    return store;
                })
                .catch(console.error);
        });
    } else {
        return Promise.resolve({getState: () => ({})}); //object with getState property, which returns empty object
    }
};

const mapDynamicArguments = (req, dynamicArgumentsMap = {}) => {
    return Object.keys(dynamicArgumentsMap).reduce((dynamicArguments, argumentName) => {
        dynamicArguments[argumentName] = _.get(req, dynamicArgumentsMap[argumentName]);
        return dynamicArguments;
    }, {});
};

const utilsLoader = (pathname) => {
    switch(pathname) {
        case '/wf-carousel-widget':
            return import('wf-carousel-widget/lib/utils/loaders.js');
        case '/wf-group-reviews-widget':
            return import('wf-group-reviews-widget/lib/utils/loaders.js');
        case '/wf-grid-widget':
            return import('wf-grid-widget/lib/utils/loaders.js');
        case '/wf-horizontal-widget':
            return import('wf-horizontal-widget/lib/utils/loaders.js');
        case '/wf-list-widget':
            return import('wf-list-widget/lib/utils/loaders.js');
        case '/wf-micro-star-widget':
            return import('wf-micro-star-widget/lib/utils/loaders.js');
        case '/wf-peer-recommendation-widget':
            return import('wf-peer-recommendation-widget/lib/utils/loaders.js');
        case '/booking-calendar':
            return import('booking-widget/lib/utils/loaders.js');
        case '/profile-reviews':
            return import('profile-reviews-widget/lib/utils/loaders.js');
        case '/reviews-badge-widget':
        case '/reviews-badge':
            return import('review-badge-widget/lib/utils/loaders.js');
        case '/':
        default:
            return Promise.resolve({loaders: [], dynamicArgumentsMap: {}});
    }
};
